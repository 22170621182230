.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-div {
  background-color: pink;
  height: 100vh;
  overflow-x: auto;
}

@media screen and (max-width: 400px) {
  .main-logo {
    width: 200px
  }
}

.title-header {
  margin-top: 0;
  padding-top: 0;
  text-align: center;
}

.incrementButton {
  display: inline-block;
}

.input-table {
  margin: 10px;
  padding: 10px;
}

/*.input-table-row {*/
/*  margin: 10px;*/
/*  padding: 10px;*/
/*}*/

/*.input-table-cell {*/
/*  margin: 10px;*/
/*  padding: 10px;*/
/*}*/

.column {
  width: 150px;
}

@media screen and (max-width: 400px) {
  .column {
    width: 50%;
  }
}

button {
  background-color: #e894bc;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  padding: 7px 14px;
}

button:hover {
  background-color: #e83c8c;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  padding: 7px 14px;
}

.consent-input {
  margin-right: 15px;
}
